
<div class="" #appContainer>
  <app-header
  [backRoute]="backRoute"
  ></app-header>
  <div class="flex flex-col items-center h-[90vh] text-center bg-gray-200 overflow-y-scroll">
    <div class="container">
      <div class="row">
        <div class="col d-flex justify-content-center my-4 text-blue font-extrabold text-2xl">
            Algo más de información...
        </div>
      </div>
  
      <div class="row">
        <div class="col text-start">
                    <form [formGroup]="form">
                        <div class="mb-3">
                          <label for="ocupation" class="form-label text-blue">¿Cuál es tu ocupación?</label>
                          <select formControlName="asotip" class="form-select" aria-label="Ocupación" id="ocupation" (change)="changeOption()"
                              [ngClass]="{'is-invalid': form.get('asotip')?.invalid && (isTimeout || form.get('asotip')?.touched)}" >
                            <option value="" selected>Selecciona...</option>
                            <option *ngFor="let item of asotips" [value]="item.id">{{item.codefixed}}</option>
                          </select>
                          <div *ngIf="form.get('asotip')?.invalid && (isTimeout || form.get('asotip')?.touched)" class="text-custom-red">Por favor selecciona un elemento de la lista</div>
                        </div>
                        <app-independient [defaultValue]="addressData" (formValues)="setChildValues($event)" [resetTimer]="resetTimer" *ngIf="form.value.asotip == 1"></app-independient>
                        <app-aggrement [defaultValue]="addressData" (formValues)="setChildValues($event)" [resetTimer]="resetTimer" *ngIf="form.value.asotip == 2"></app-aggrement>
                        <app-pensioner [defaultValue]="addressData" (formValues)="setChildValues($event)" [resetTimer]="resetTimer" *ngIf="form.value.asotip == 3"></app-pensioner>
                        <app-studient [defaultValue]="addressData.income" (formValues)="setChildValues($event)" [resetTimer]="resetTimer" *ngIf="form.value.asotip == 4"></app-studient>
                        <app-agricultural [defaultValue]="addressData" (formValues)="setChildValues($event)" [resetTimer]="resetTimer" *ngIf="form.value.asotip == 5"></app-agricultural>
                        
                        <app-locations [defaultValue]="addressData.location" (valueState)="setLocation($event)" [resetTimer]="resetTimer"></app-locations>
                        <div class="mb-3">
                            <label for="address" class="form-label text-blue">Dirección de Domicilio</label>
                            <input formControlName="address" type="text" class="form-control" id="address" aria-describedby="addressHelp" (keyup)="setAddress()"
                              [ngClass]="{'is-invalid': form.get('address')?.invalid && (isTimeout || form.get('address')?.touched)}" >
                            <div *ngIf="form.get('address')?.invalid && (isTimeout || form.get('address')?.touched)" class="text-custom-red">Por favor escribe una dirección válida mayor a 8 y menor a 55 carácteres</div>
                          </div>  
                        <div class="mb-3">
                            <label for="neighborhood" class="form-label text-blue">Barrio Domicilio</label>
                            <input formControlName="neighborhood" type="text" class="form-control" id="neighborhood" aria-describedby="neighborhoodHelp" (keyup)="setNeighborhood()"
                              [ngClass]="{'is-invalid': form.get('neighborhood')?.invalid && (isTimeout || form.get('neighborhood')?.touched)}" >
                            <div *ngIf="form.get('neighborhood')?.invalid && (isTimeout || form.get('neighborhood')?.touched)" class="text-custom-red">Por favor ingresa un barrio mayor a 3 y mayor a 30 caracteres</div>
                        </div>  

                        <div class="mb-3" *ngIf="form.get('asotip').value == 1">
                          <label for="automaticDebit" class="form-label text-blue">¿Desea habilitar el débito automático?</label>
                          <div class="form-check">
                            <input
                            formControlName="automaticDebit"
                            class="form-check-input"
                            type="radio"
                            id="automaticDebitYes"
                            name="automaticDebit" 
                            [value]="true"
                            [ngClass]="{
                              'is-invalid': form.get('automaticDebit')?.invalid && (isTimeout || form.get('automaticDebit')?.touched)
                            }"
                            (click)="setAutomaticDebit()"
                          />
                          <label class="form-check-label" for="automaticDebitYes">Sí</label>
                        </div>
                        <div class="form-check">
                          <input
                            formControlName="automaticDebit"
                            class="form-check-input"
                            type="radio"
                            id="automaticDebitNo"
                            name="automaticDebit"
                            [value]="false"
                            [ngClass]="{
                              'is-invalid': form.get('automaticDebit')?.invalid && (isTimeout || form.get('automaticDebit')?.touched)
                            }"
                            (click)="setAutomaticDebit()"
                          />
                          <label class="form-check-label" for="automaticDebitNo">No</label>
                        </div>
                          <div
                            *ngIf="form.get('automaticDebit')?.invalid && (isTimeout || form.get('automaticDebit')?.touched)"
                            class="text-custom-red"
                          >
                            Por favor selecciona una opción.
                          </div>
                        </div>

                        <app-automatic-debit *ngIf="form.get('automaticDebit').value == true" 
                        (formValueChange)="onFormValueChange($event)"
                        [debitData] = "dataDebit"></app-automatic-debit>
                        
                    </form>
        </div>
      </div>
      
      <div class="button-next">
        <button
          type="button"
          [disabled]="!allValid"
          class="btn btn-primary mt-2"
          (click)="saveData()"

        >
          Continuar
        </button>
      </div>
    </div>
  </div>
</div>
  
  <app-wow *ngIf="wowDisplay" (completed)="next()"></app-wow>
  <app-modal-continue *ngIf="modalContinue" (modalState)="setModal($event)" (completed)="next()"></app-modal-continue>
 <app-button-help *ngIf="!wowDisplay"></app-button-help>

<app-modal-shared *ngIf="isValidAddress"
[title]="'Error al procesar la dirección'"
[srcImg]="'assets/svg/NotFoundAddress.svg'"
[text]="'No hemos podido encontrar tu dirección o barrio. Por favor, verifica los datos e intenta nuevamente.'"
[buttonText]="'Aceptar'"
(buttonAction)="closeModal()"
></app-modal-shared>