<form [formGroup]="form">
  <div class="mb-3">
      <label for="codpro" class="form-label text-blue">{{ label }}</label>
      <input type="text" class="form-control" #codpro id="codpro" formControlName="codpro" aria-describedby="codproHelp" 
        placeholder=""
        [ngClass]="{'is-invalid': !isValid && (isTimeout || form.get('codpro')?.touched)}"
        (blur)="validateBlur()" 
        (keydown)="makeInvalid()"
        (input)="filterProfession($event)"
        (focus)="filterProfession($event)"
        (keydown.escape)="esc()">

        <ul id="list" class="max-h-[300px] relative overflow-y-scroll mt-2 bg-white border border-gray-200">
          <div class="relative">
            <!-- <app-loader *ngIf="isLoading"></app-loader> -->
            <li class="list-group-item p-2 hover:bg-gray-100 cursor-pointer" *ngFor="let codpro of filteredCodpros" (click)="setProfession(codpro.id, codpro.name)">
              {{ codpro.name }}
            </li>
          </div>
        </ul>     
      <div *ngIf="!isValid && (isTimeout || form.get('codpro')?.touched)" class="text-custom-red">Por favor selecciona un elemento de la lista.</div>
  </div>
</form>
