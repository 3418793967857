import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DebitBanksService } from '../../services/debit-banks.service';

@Component({
  selector: 'app-automatic-debit',
  standalone: false,
  templateUrl: './automatic-debit.component.html',
  styleUrl: './automatic-debit.component.scss'
})
export class AutomaticDebitComponent implements OnInit {
  @Output() formValueChange = new EventEmitter<any>(); // Para emitir los valores del formulario
  @Input() debitData: any;
  form: FormGroup;
  isTimeout: boolean = false;

  bankOptions: string[] = [];

  constructor(private fb: FormBuilder, private _debitBankService: DebitBanksService) {
    this.form = this.fb.group({
      debitType: [null, Validators.required],
      bank: [null],
      accountNumber: [null],
      accountType: [null],
      debitDay: [null]
    });

    this.form.valueChanges.subscribe(() => {
      if (this.form.valid) {
        this.formValueChange.emit(this.form.value); // Emitir valores válidos
      }
    });

    this.form.get('debitType')?.valueChanges.subscribe((value) => {
      if (value === 'external') {
        this.enableExternalFields();
      } else {
        this.disableExternalFields();
      }
    });
  }

  ngOnInit(): void {
    this._debitBankService.getAll().subscribe({
      next: (response: any) => {
        this.bankOptions = response.data;
        this.form.patchValue(this.debitData)
      }
    });
  }

  private enableExternalFields(): void {
    this.form.get('bank')?.setValidators(Validators.required);
    this.form.get('accountNumber')?.setValidators(Validators.required);
    this.form.get('accountType')?.setValidators(Validators.required);
    this.form.get('debitDay')?.setValidators([Validators.required, Validators.min(1), Validators.max(31)]);
    this.form.get('bank')?.updateValueAndValidity();
    this.form.get('accountNumber')?.updateValueAndValidity();
    this.form.get('accountType')?.updateValueAndValidity();
    this.form.get('debitDay')?.updateValueAndValidity();
  }

  private disableExternalFields(): void {
    this.form.get('bank')?.clearValidators();
    this.form.get('accountNumber')?.clearValidators();
    this.form.get('accountType')?.clearValidators();
    this.form.get('debitDay')?.clearValidators();
    this.form.get('bank')?.updateValueAndValidity();
    this.form.get('accountNumber')?.updateValueAndValidity();
    this.form.get('accountType')?.updateValueAndValidity();
    this.form.get('debitDay')?.updateValueAndValidity();
  }

  onSubmit(): void {
    if (this.form.valid) {
      const formData = this.form.value;
      this.formValueChange.emit(formData);
    } else {
      this.isTimeout = true;
    }
  }
}
