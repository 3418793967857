import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ListService } from '../../services/list.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-profession',
  templateUrl: './profession.component.html',
  styleUrl: './profession.component.scss'
})
export class ProfessionComponent {

  codpros: any[] = [];
  form: FormGroup;
  isValid = false;
  @Input() disabled = false;
  @Input() resetTimer = false;
  @Input() defaultValue: any = null;
  @Input() label = '¿Cuál es tu profesión?';
  @Input() timerMs = 5000;
  @Output() valueState = new EventEmitter<number | null>();
  @ViewChild('codpro') codpro!: ElementRef;
  timer: any;
  isTimeout = false;
  filterTimeout: any;
  selectedCodpro: any = null;
  filteredCodpros: any[] = [];

  constructor(private listService: ListService) {
    this.form = new FormGroup({
      codpro: new FormControl('', [Validators.required]),
    });
  }

  ngOnInit(): void {
    this.timer = setTimeout(() => {
      this.isTimeout = true;
    }, this.timerMs);
    this.listService.getCodpro().subscribe({
      next: (response: any) => {
        this.codpros = response.list;
        this.setProfessionDefaultValue(this.defaultValue)
      },
    });
    if(this.disabled) {
      this.form.get('codpro')!.disable();
    }
  }

  ngOnChanges(): void {
    if(this.resetTimer) {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.isTimeout = true;
      }, this.timerMs);
    }
    if(this.defaultValue) {
      
    }
    if(this.disabled) {
      this.form.get('codpro')!.disable();
    }
  }

  setProfessionDefaultValue(defaultValue) {
    this.form.get('codpro')!.setValue(defaultValue);
    if(this.defaultValue) {
      this.isValid = true;
    }
    const codproObj = this.codpros.filter(codpro => codpro.id == defaultValue)[0];
    if(codproObj) {
      this.codpro.nativeElement.value = codproObj.name;
    }
  }

  filterProfession(event: any) {
    this.isValid = false;
    const query = event.target.value.toLowerCase();
    if(this.filterTimeout) {
      clearTimeout(this.filterTimeout);
    }
    this.filteredCodpros = this.codpros.filter(codpro => codpro.name.toLowerCase().includes(query));
  }

  setProfession(idCodpro: number, codpro: string) {
    this.form.get('codpro')!.setValue(codpro);
    this.isValid = true;
    this.selectedCodpro = codpro;
    this.valueState.emit(idCodpro);
    this.filteredCodpros = [];
  }

  esc(){
    this.filteredCodpros = [];
    this.form.get('codpro')!.setValue('');
    this.isValid = false;
    this.valueState.emit(null);
  }

  makeInvalid() {
    this.isValid = false;
    this.valueState.emit(null);
  }

  validateBlur() {
    setTimeout(() => {
      if(!this.isValid){
        this.esc()
      }
    }, 1000);
  }
}